import React, { useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet, WalletContextState } from "@solana/wallet-adapter-react";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import axios from "axios";
import SideBar from "../SideBar";
import LiveChat from "../LiveChat";
import FundModal from "./FundModal";
import SpinModal from "./SpinModal";
import CardModal from "./CardModal";
import GiftModal from "./GiftModal";
import AnnouncementModal from "./AnnouncementModal";
import LandingPage from "./LandingPage";
import { CombinedReducer } from "../../store";
import User, { IUserDailyStatus } from "../../interfaces/User";
import { Sockets } from "../../reducers/sockets";
import { displayName, getFormattedTokenBalance } from "../../utils";
import { IGift } from "../../interfaces/Gift";
import { IAnnouncement } from "../../interfaces/Announcement";
import { FreeChanceTypeEnum } from "../../enums";
import menuIcon from "../../assets/menu.svg";
import chatIcon from "../../assets/chat.svg";
import logoImgIcon from "../../assets/logo_img.svg";
import logoTextIcon from "../../assets/logo_text.svg";
import giftIcon from "../../assets/gift.svg";
import lightGraySolIcon from "../../assets/sol-symbol-light-gray.svg";
import boneIcon from "../../assets/bone.svg";
import useSound from "use-sound";
import OnchainNotifyContainerBadgeLg from "../svgs/OnchainNotifyContainerBadgeLg";
import OnchainNotifyContainerBadgeSm from "../svgs/OnchainNotifyContainerBadgeSm";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";

import "./index.scss";

const plainMessage = "Login to the BiteClub";
const messageToSign = Uint8Array.from(Buffer.from(plainMessage));
const MEMO_PROGRAM_ID = new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr");

let flag = false;
let flag2 = false;

const Navbar = () => {
  const navigate = useNavigate();
  const wallet = useWallet();
  const dispatch = useDispatch();
  const [playFeature] = useSound("/sound/feature.mp3", { volume: 0.2 });
  const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [showSideMenubar, setShowSideMenubar] = useState<boolean>(false);
  const [showLiveChat, setShowLiveChat] = useState<boolean>(false);
  const [showFundModal, setFundShowModal] = useState<boolean>(false);
  const [shwoSpinModal, setShowSpinModal] = useState<boolean>(false);
  const [showCardModal, setShowCardModal] = useState<boolean>(false);
  const [showGiftModal, setShowGiftModal] = useState<boolean>(false);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState<boolean>(false);
  const [showLandingPage, setShowLandingPage] = useState<boolean>(true);
  const [checkedPage, setCheckedPage] = useState<boolean>(false);
  const [freeChanceType, setFreeChanceType] = useState<FreeChanceTypeEnum>(FreeChanceTypeEnum.Card);
  const [gifts, setGifts] = useState<IGift[]>([]);
  const [showDepositNotifyMessage, setshowDepositNotifyMessage] = useState<boolean>(true);
  const [checkedDepositNavigation, setcheckedDepositNavigation] = useState<boolean>(false);
  const [activeAnnouncements, setActiveAnnouncements] = useState<IAnnouncement[]>([]);
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const user = useSelector<CombinedReducer, User>((state) => state.user);
  const sockets = useSelector<CombinedReducer, Sockets>((state) => state.sockets);
  const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");

  const depositNotifyContainerRef = useRef<HTMLDivElement | null>(null);

  const buildAuthTx = async (nonce: string): Promise<Transaction> => {
    const tx = new Transaction();
    tx.add(
      new TransactionInstruction({
        programId: MEMO_PROGRAM_ID,
        keys: [],
        data: Buffer.from(nonce, "utf8"),
      })
    );
    return tx;
  };

  const handleShowMenubar = (status: boolean) => {
    setShowSideMenubar(status); // Update the sidebar state
    setIsSidebarActive(status); // Update the rotation state

    // Update body overflow based on the new state
    if (status) {
      //document.body.style.overflow = "hidden";
    } else {
      //document.body.style.overflow = "visible";
    }
  };

  const handleShowLiveChat = (status: boolean) => {
    if (status) {
      // document.body.style.overflow = "hidden"
    } else {
      // document.body.style.overflow = "visible"
    }
    setShowLiveChat(status);
  };

  const handleShowFundModal = (e: any, status: boolean) => {
    if (
      depositNotifyContainerRef.current &&
      depositNotifyContainerRef.current.contains(e.target)
    ) {
      return;
    }

    setFundShowModal(status);
  };

  const handleShowChanceModal = (status: boolean) => {
    if (status) {
      // document.body.style.overflow = "hidden"
    } else {
      // document.body.style.overflow = "visible"
    }

    if (freeChanceType == FreeChanceTypeEnum.Spin) {
      setShowSpinModal(status);
    } else {
      setShowCardModal(status);
    }
  };

  const handleFetchingGifts = async () => {
    try {
      const gifts = (await axios.post("/api/gift/getGifts")).data;
      setGifts(gifts);
    } catch (e) {
      console.log("error in fetching gifts");
      setGifts([]);
    }
  };

  const handleFetchingAnnouncements = async () => {
    try {
      let announcements: IAnnouncement[] = (
        await axios.get("/api/announcement/getActiveAnnouncements")
      ).data;
      let checkedIdsString: string | null = localStorage.getItem(
        "checkedAnnouncementIds"
      );
      if (checkedIdsString !== null) {
        announcements = announcements.filter(
          (x) => !JSON.parse(checkedIdsString!).includes(x._id)
        );
      }

      setActiveAnnouncements(announcements);
      setShowAnnouncementModal(true);
    } catch (e) {
      console.log("error in fetching announcements");
      setActiveAnnouncements([]);
    }
  };

  const handleCheckGift = async (giftId: string) => {
    try {
      setShowGiftModal(false);
      await axios.post("/api/gift/checkGift", { giftId });

      setGifts((currentGifts) => {
        return currentGifts.filter((x) => x._id != giftId);
      });
    } catch (e) {
      console.log("error in fetching gifts");
      setGifts([]);
    }
  };

  const handleCheckAnnouncement = async (checked: boolean) => {
    try {
      if (checked) {
        let checkedIdsString: string | null = localStorage.getItem(
          "checkedAnnouncementIds"
        );
        const activeIds = activeAnnouncements.map((x) => x._id);
        if (!checkedIdsString) {
          localStorage.setItem(
            "checkedAnnouncementIds",
            JSON.stringify(activeIds)
          );
        } else {
          localStorage.setItem(
            "checkedAnnouncementIds",
            JSON.stringify([...JSON.parse(checkedIdsString), ...activeIds])
          );
        }

        setActiveAnnouncements([]);
      }
    } catch { }
    setShowAnnouncementModal(false);
  };

  const useMenuOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleShowMenubar(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const useChatOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleShowLiveChat(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const sideBarRef = useRef(null);
  const liveChatRef = useRef(null);

  useMenuOutsideAlerter(sideBarRef);
  // useChatOutsideAlerter(liveChatRef);

  useEffect(() => {
    if (pathname !== "/" && pathname !== "/admin" && !checkedPage) {
      (async () => {
        await handleFetchingAnnouncements();
        setCheckedPage(true);
      })();
    }
  }, [pathname]);

  useEffect(() => {
    const storedValue = window.localStorage.getItem("showDepositNotifyMessage");
    if (storedValue === "false") {
      setshowDepositNotifyMessage(false);
    }
  }, []);

  useEffect(() => {
    if (!(sockets && sockets?.announcement)) return;

    if (flag2) return;
    flag2 = true;

    sockets.announcement.on(
      "newAnnouncement",
      (newAnnouncement: IAnnouncement) => {
        setActiveAnnouncements((currentAnnouncements) => {
          return [...currentAnnouncements, newAnnouncement];
        });

        if (pathname !== "/" && pathname !== "/admin") {
          setShowAnnouncementModal(true);
        }
      }
    );
  }, [sockets?.announcement]);

  useEffect(() => {
    if (!(sockets && sockets.user)) return;

    if (user?._id) {
      sockets.user.emit("subscribeToProfile", user._id);
    }

    if (flag) return;
    flag = true;

    sockets.user.on("balanceChange", (amount: number, fromDeposit: boolean) => {
      console.log("updated amount", amount);
      dispatch({ type: "UPDATE_USER_BALANCE", payload: amount });
    });

    sockets.user.on("dailyRewards", (amount: number) => {
      dispatch({ type: "UPDATE_USER_TOTAL_DAILY_REWARDS", payload: amount });
    });

    sockets.user.on("getChance", (isSpin: boolean) => {
      playFeature();
      dispatch({ type: "USER_GET_CHANCE", payload: isSpin });
    });

    sockets.user.on(
      "updateUserDailyStatus",
      (dailyStatus: IUserDailyStatus) => {
        dispatch({ type: "USER_UPDATE_DAILY_STATUS", payload: dailyStatus });
      }
    );

    sockets.user.on("newGift", (newGift: IGift) => {
      setGifts((currentGifts) => {
        return [...currentGifts, newGift];
      });
    });
  }, [sockets?.user, user?._id]);

  useEffect(() => {
    if (gifts.length > 0) {
      setTimeout(() => {
        setShowGiftModal(true);
      }, 500);
    }
  }, [gifts.length]);

  useEffect(() => {
    if (user?.bannedStatus) {
      navigate("/bannedUser");
    }
  }, [user?.bannedStatus]);

  useEffect(() => {
    if (user) {
      if (user?.isGetChance && !user?.isGetCardChance) {
        setFreeChanceType(FreeChanceTypeEnum.Spin);
      }

      if (!user?.isGetChance && user?.isGetCardChance) {
        setFreeChanceType(FreeChanceTypeEnum.Card);
      }

      handleFetchingGifts();
    } else {
      setFreeChanceType(FreeChanceTypeEnum.None);
    }
  }, [user]);

  useEffect(() => {
    console.log("wallet?.disconnecting", wallet?.disconnecting);
    if (wallet?.disconnecting) {
      (async () => {
        await axios.get("/api/auth/logout");
        dispatch({ type: "LOAD_USER", payload: null });
      })();
    }
  }, [wallet?.disconnecting]);

  useEffect(() => {
    const signAndAuth = async (wallet: WalletContextState) => {
      try {
        if (!wallet || !wallet?.signMessage || !wallet.publicKey) return;

        let { authenticated, user } = (await axios.get("/api/auth/state")).data;
        console.log({ authenticated });
        if (authenticated) {
          if (user.publicKey === wallet.publicKey.toBase58()) {
            // await connectUser(user);
            dispatch({ type: "LOAD_USER", payload: user });
            return;
          }
          console.log("PUBLIC KEYS ARE NOT THE SAME!");
          await axios.get("/api/auth/logout");
          dispatch({ type: "LOAD_USER", payload: null });
        }

        const publicKey = wallet.publicKey.toBytes();
        let signedMessage;

        let signedTx;
        let serializedTransaction;
        let isLedger = false;

        try {
          signedMessage = await wallet.signMessage!(messageToSign);
        } catch {
          const tx = await buildAuthTx(plainMessage);

          tx.feePayer = wallet.publicKey;
          tx.recentBlockhash = (
            await connection.getLatestBlockhash()
          ).blockhash;

          signedTx = await wallet.signTransaction!(tx);
          serializedTransaction = signedTx.serialize();
          isLedger = true;
        }

        user = (
          await axios.post(
            "/api/auth/login",
            {
              publicKey: Array.from(publicKey),
              signedMessage: signedMessage ? Array.from(signedMessage) : null,
              serializedTransaction: serializedTransaction
                ? Array.from(serializedTransaction)
                : null,
              isLedger,
              ref: searchParams.get("ref"),
            },
            { withCredentials: true }
          )
        ).data;

        // await connectUser(user);
        dispatch({ type: "LOAD_USER", payload: user });
      } catch (e: any) {
        toast.error(e?.response?.data?.message?.toString());
        console.log(e);
      }
    };

    signAndAuth(wallet);
  }, [wallet]);

  return (
    <nav className="fixed top-0 z-30 h-[59px] md:h-[70px] w-full bg-[#090C0E] transition-all duration-300 right-0">
      {/* Tablet, Mobile Sidebar */}
      <div
        className={`${showSideMenubar ? "opacity-100 visible" : "opacity-0 invisible"
          } overlay fixed top-[59px] md:top-[70px] right-0 bg-[#090C0E]/30 w-full h-full z-[60] transition`}
      ></div>
      <div
        ref={sideBarRef}
        className={`${showSideMenubar ? "translate-x-0" : "-translate-x-[140px] opacity-0"
          } top-[59px] md:top-[70px] left-0 h-full fixed transition-all z-[70]`}
      >
        <SideBar
          showSideMenubar={showSideMenubar}
          handleShowSideMenubar={handleShowMenubar}
        />
      </div>

      <LandingPage show={showLandingPage} handleShow={setShowLandingPage} />

      {/* Navbar Mainbody */}
      <div className="flex justify-between items-center h-full px-[20px] md:pl-[27px] md:pr-[27px] relative">
        <div className="flex justify-start items-center gap-[10px]">
          {/* Menu button for mobile */}
          <button
            className={`xl:hidden transform transition-transform duration-300 ${isSidebarActive ? "rotate-90" : "rotate-0"
              }`}
            onClick={() => handleShowMenubar(!isSidebarActive)}
            onMouseEnter={() => playHover()}
          >
            <img src={menuIcon} alt="menu-icon" />
          </button>

          <div
            className="hidden sm:flex justify-center items-center gap-[10px] cursor-pointer"
            onClick={() => navigate("/")}
            onMouseEnter={() => playHover()}
          >
            <img src={logoImgIcon} alt="logo" />
            <img src={logoTextIcon} alt="logo" />
          </div>

          {/*                     <div className={`text-[20px] ${(routes.find(x => x.to == pathname)?.font || 'font-primary')} ${pathname === '/dice' || pathname === '/dice2' ? 'block' : 'hidden md:block'} font-extrabold`}>
                        {
                            pathname === "/"
                                ?
                                "HOME"
                                :
                                pathname === '/dice' || pathname === '/dice2'
                                    ? (
                                        <div className="flex flex-col md:flex-row justify-center items-center gap-[2px] md:gap-[6px]">
                                            <Link to={'/dice2'} className={`flex justify-center items-center w-[45px] h-[18px] md:w-[80px] md:h-[35px] rounded-[8px_8px_0px_0px] md:rounded-[20px_0px_0px_20px] hover:bg-[#3D4E5B] ${pathname === '/dice2' ? 'bg-[#3D4E5B] shadow-[0px_0px_5px_-2px_#FFF_inset]' : 'bg-[#21272C]'}`}>
                                                <span className="text-[10px] md:text-[16px] font-extrabold leading-[1px]">PVE</span>
                                            </Link>
                                            <Link to={'/dice'} className={`flex justify-center items-center w-[45px] h-[18px] md:w-[80px] md:h-[35px] rounded-[0px_0px_8px_8px] md:rounded-[0px_20px_20px_0px] hover:bg-[#3D4E5B] ${pathname === '/dice' ? 'bg-[#3D4E5B] shadow-[0px_0px_5px_-2px_#FFF_inset]' : 'bg-[#21272C]'}`}>
                                                <span className="text-[10px] md:text-[16px] font-extrabold leading-[1px]">PVP</span>
                                            </Link>
                                        </div>
                                    ) : (
                                        routes.find(x => x.to == pathname)?.displayName || pathname.split('/')[1]
                                    )
                        }
                    </div> */}

          {/* Future location for Gift Icon */}
          {(user?.isGetChance || user?.isGetCardChance) && (
            <button
              onClick={() => handleShowChanceModal(true)}
              onMouseEnter={() => playHover()}
            >
              <img
                src={giftIcon}
                alt="Gift-Icon"
                className="glow-svg animating-gift w-[35px] md:w-[45px] pb-1 pl-1 md:pl-2"
              />
            </button>
          )}
        </div>

        {/* Mobile Logo */}
        <div className="w-full h-full absolute top-0 left-0 z-[-1] grow text-center flex flex-col justify-center gap-0 xl:hidden">
          <div className="flex justify-center items-center gap-[8px] relative">
            <img src={logoImgIcon} alt="logo" className="w-[24px] md:w-fit" />
            {/* <img src={logoTextIcon} alt="logo" className="w-[69px] md:w-fit" /> */}
            {wallet?.connected && (
              <span className="absolute w-full bottom-[-12px] text-[8px] text-[#00A5FE] md:hidden">
                Connected
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-end items-center gap-[19px] md:gap-[22px]">
          <div className="flex flex-col md:flex-row justify-center items-center gap-[5px] md:gap-[15px]">
            {!!wallet?.publicKey && (
              <>
                {pathname == "/store" && (
                  <div className="flex justify-between items-center w-full md:w-fit h-[22px] md:h-[40px] bg-[#222C36] border border-solid border-[#808080] rounded-full px-[8px] md:px-[14px] gap-[5px] md:gap-[7px]">
                    <div className="block md:hidden w-[11px]"></div>
                    <div className="flex justify-center items-center gap-[5px] md:gap-[7px]">
                      <div className="text-[14px] md:text-[16px] font-medium">
                        {`${getFormattedTokenBalance(
                          (user?.onchainTokenBalance || 0) / LAMPORTS_PER_SOL
                        )}`}
                      </div>
                      <img
                        src={boneIcon}
                        className="w-[16px] md:w-[20px]"
                        alt="bitcoin"
                      />
                    </div>
                  </div>
                )}

                {pathname != "/On-Chain" &&
                  pathname != "/sol-shuffle" &&
                  pathname != "/store" ? (
                  <div
                    className="relative flex justify-center items-center w-full md:w-fit h-[22px] md:h-[40px] bg-[#222C36] border border-solid border-[#808080] rounded-full px-[8px] md:px-[14px] gap-[5px] md:gap-[7px] cursor-pointer transform hover:bg-[#32404E] transition-all duration-150"
                    onClick={(e) => handleShowFundModal(e, true)}
                    onMouseEnter={() => playHover()}
                  >
                    <div className="text-[14px] md:text-[16px] font-medium">
                      {`${(
                        Math.floor(
                          ((user?.balance || 0) * 100) / LAMPORTS_PER_SOL
                        ) / 100
                      ).toFixed(2)}`}
                    </div>
                    <img
                      src={lightGraySolIcon}
                      className="w-[16px] md:w-[20px]"
                      alt="sol-symbol"
                    />

                    {/* Deposit & Withdraw notify popup */}
                    <div className="absolute top-[-9px] md:top-[9.5px] right-[0px] md:right-[0px]">
                      {showDepositNotifyMessage && (
                        <div
                          ref={depositNotifyContainerRef}
                          className="absolute top-[30px] right-0 z-10 w-[134px] md:w-[198px] h-[69px] md:h-[73px]"
                        >
                          <div className="relative w-full h-full">
                            <OnchainNotifyContainerBadgeLg className="w-full hidden md:block" />
                            <OnchainNotifyContainerBadgeSm className="w-full block md:hidden" />

                            <div className="absolute w-full top-0 left-0 pt-[22px] md:pt-[20px]">
                              <p className="w-full text-[11.5px] md:text-[18px] text-left md:text-center font-bold px-[13px]">
                                Deposit / Withdraw
                              </p>

                              <div className="flex justify-between items-center w-full pl-[13px] pr-[6px] mt-[2px] md:mt-0">
                                <div className="flex justify-center items-center gap-[10px]">
                                  <div
                                    className="relative w-[10px] h-[10px] bg-[#090C0E] border border-solid border-[#808080] cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#222c36]"
                                    onClick={() =>
                                      setcheckedDepositNavigation(
                                        !checkedDepositNavigation
                                      )
                                    }
                                    onMouseEnter={() => playHover()}
                                  >
                                    {checkedDepositNavigation && (
                                      <div className="w-full h-full absolute top-0 left-0">
                                        <CheckIcon className="w-full h-full" />
                                      </div>
                                    )}
                                  </div>
                                  <span className="text-[9px] md:text-[14px] text-[#808080] font-medium leading-[1]">
                                    Don’t show again
                                  </span>
                                </div>

                                <button
                                  className="flex justify-center items-center text-[10px] font-medium leading-[1] w-[25px] h-[18px] bg-[#222C36] border border-solid border-[#808080] rounded-[2px] transition-all duration-300 ease-in-out hover:bg-[#32404E]"
                                  onClick={() => {
                                    if (checkedDepositNavigation) {
                                      window.localStorage.setItem(
                                        "showDepositNotifyMessage",
                                        "false"
                                      );
                                    }
                                    setshowDepositNotifyMessage(false);
                                  }}
                                  onMouseEnter={() => playHover()}
                                >
                                  OK
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center w-full md:w-fit h-[22px] md:h-[40px] bg-[#222C36] border border-solid border-[#808080] rounded-full px-[8px] md:px-[14px] gap-[5px] md:gap-[7px]">
                    <div className="text-[14px] md:text-[16px] font-medium">
                      {`${(
                        Math.floor(
                          ((user?.onchainUserBalance || 0) * 1000) /
                          LAMPORTS_PER_SOL
                        ) / 1000
                      ).toFixed(3)}`}
                    </div>
                    <img
                      src={lightGraySolIcon}
                      className="w-[16px] md:w-[20px]"
                      alt="sol-symbol"
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <div className="hidden md:flex">
            {user && (
              <WalletMultiButton className="gradient-btn before:!border !rounded-full before:!rounded-full">
                {displayName(user)}
              </WalletMultiButton>
            )}
          </div>

          {!wallet?.publicKey && (
            <WalletMultiButton className="gradient-btn justify-center before:!border !rounded-full before:!rounded-full md:!w-[163px] !h-[30px] md:!h-[40px] !px-0 text-[14px] md:text-[16px] text-center font-medium">
              Connect Wallet
            </WalletMultiButton>
          )}
        </div>
      </div>

      {/* Tablet, Mobile LiveChat */}
      <button
        className="xl:hidden fixed right-[18px] bottom-[12px]"
        onClick={() => handleShowLiveChat(true)}
        onMouseEnter={() => playHover()}
      >
        <img src={chatIcon} alt="chat-icon" />
      </button>

      <div
        className={`${showLiveChat ? "opacity-100 visible" : "opacity-0 invisible"
          } overlay fixed top-[59px] md:top-[70px] left-0 bg-[#090C0E]/0 w-full h-full z-[60] transition`}
      ></div>
      <div
        ref={liveChatRef}
        className={`${showLiveChat ? "translate-x-0" : "translate-x-full"
          } w-screen sm:w-[336px] top-[59px] md:top-[70px] right-0 h-full fixed transition-all z-[70]`}
      >
        <LiveChat show={showLiveChat} handleShowLiveChat={handleShowLiveChat} />
      </div>

      {/* Fund Modal */}
      <FundModal show={showFundModal} handleShow={setFundShowModal} />

      {/* Spin Modal */}
      <SpinModal show={shwoSpinModal} handleShow={handleShowChanceModal} />

      {/* Spin Modal */}
      <CardModal show={showCardModal} handleShow={handleShowChanceModal} />

      {/* Gift Modal */}
      {gifts.length > 0 && (
        <GiftModal
          show={showGiftModal}
          giftId={gifts[0]._id}
          giftSenderName={gifts[0].senderName}
          giftSenderPubkey={gifts[0].senderPubkey}
          giftAmount={gifts[0].giftAmount}
          giftMessage={gifts[0].giftMessage}
          giftType={gifts[0].giftType}
          handleShow={setShowGiftModal}
          handleCheckGift={handleCheckGift}
        />
      )}

      {/* Announcement Modal */}
      {activeAnnouncements.length > 0 && (
        <AnnouncementModal
          show={showAnnouncementModal}
          announcements={activeAnnouncements}
          handleShow={setShowAnnouncementModal}
          handleCheckAnnouncement={handleCheckAnnouncement}
        />
      )}
    </nav>
  );
};

export default Navbar;
