import React, { useEffect, useState } from "react";
import { IAnnouncement } from "../../interfaces/Announcement";
import useSound from "use-sound";
// @ts-ignore
import Slide from "react-reveal/Slide";
import { Checkbox } from "@mui/material";

interface IProps {
  show: boolean;
  announcements: IAnnouncement[];
  handleShow: (status: boolean) => void;
  handleCheckAnnouncement: (checked: boolean) => Promise<void>;
}

const AnnouncementModal = (props: IProps) => {
  const {
    show,
    announcements,
    handleShow,
    handleCheckAnnouncement,
  } = props;
  const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      // document.body.style.overflow = "hidden"
    } else {
      // document.body.style.overflow = "visible"
    }
  }, [show]);

  return (
    <Slide top duration={500} when={show}>
      <div
        className="justify-center fixed top-0 left-0 right-0 bottom-0 opacity-100 transition-[opacity_linear_150ms] sm:bg-black/50 z-[1040] overflow-y-auto"
        style={{ display: show ? "flex" : "none" }}
      >
        {/* Modal */}
        <div className="w-full h-full flex flex-col justify-center items-center mx-[10px] sm:mx-[25px] py-[30px] xl:px-[155px]">
          {/* Modal Wrap */}
          <div
            className={`relative flex flex-col items-center w-full max-w-[900px] h-fit md:h-full max-h-[750px] p-[44px_20px]  border border-solid z-[1050] rounded-[10px] border-[#00A5FE]`}
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 165, 254, 0.05) 0%, rgba(0, 165, 254, 0.00) 38.73%), linear-gradient(90deg, rgba(0, 165, 254, 0.05) 0%, rgba(0, 165, 254, 0.00) 25.72%), linear-gradient(0deg, rgba(0, 165, 254, 0.05) 0%, rgba(0, 165, 254, 0.00) 24%), linear-gradient(270deg, rgba(0, 165, 254, 0.05) 0%, rgba(0, 165, 254, 0.00) 25.28%), #090C0E",
            }}
          >

            {/* Close button */}
            <button
              className="sm:flex justify-center items-center absolute top-0 right-0 p-[10px] cursor-pointer bg-none border-none rounded-[6px] transform hover:scale-110 transition-all"
              onClick={() => handleShow(false)}
              onMouseEnter={() => playHover()}
            >
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 16L16 1M1 1L16 16" stroke="#BEBEBE" strokeWidth="2" />
              </svg>
            </button>

            {/* Modal body */}
            <div className="w-full h-full">
              <div className="flex flex-col items-center gap-[42px] w-full h-full max-w-[644px] mx-auto">
                <div className="flex flex-col grow items-center gap-[20px] overflow-y-auto no-scroll-bar">
                  {
                    announcements.map((item, index) => {
                      return (
                        <div key={index} className="flex flex-col items-center gap-[42px] w-full">
                          {/* Title */}
                          <div className="flex justify-center items-center text-[20px] md:text-[24px] text-[#00A5FE] font-inter font-bold leading-normal">
                            {item.title}
                          </div>

                          {/* Description */}
                          <div className="flex flex-col gap-[12px] md:gap-[16px] text-[12px] md:text-[16px] text-white font-inter font-normal">
                            {
                              item.description.split(/\n/).map((content, index) => {
                                return (
                                  <div key={index}>{content}</div>
                                )
                              })
                            }
                          </div>

                          {/* Image */}
                          {
                            item?.imageUrl && (
                              <div className="w-full max-w-[500px]">
                                <img src={item?.imageUrl} className="w-full" />
                              </div>
                            )
                          }
                        </div>
                      )
                    })
                  }
                </div>

                {/* Confirm button */}
                <div className="flex flex-col justify-center gap-[12px]">
                  <button
                    className="flex justify-center items-center gift-model-button w-[146px] md:w-[164px] h-[30px] md:h-[34px] text-[12px] md:text-[16px] font-bold rounded-[10px] border border-solid border-[#00a5fe]"
                    onClick={() => handleCheckAnnouncement(checked)}
                    onMouseEnter={() => playHover()}
                  >
                    Let’s Go!
                  </button>

                  <div className="flex justify-center items-center gap-[3.5px] text-[12px] text-[#808080]">
                    <Checkbox
                      sx={{
                        width: '18px',
                        height: '18px',
                        color: '#808080',
                        '&.Mui-checked': {
                          color: '#808080',
                        },
                        '&.MuiCheckbox-root svg': {
                          width: '18px',
                          height: '18px',
                        },
                      }}
                      onChange={e => setChecked(e.target.checked)}
                    />
                    <span className="pt-[1px]">Don't show again</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default AnnouncementModal;
