import DiscordIcon from "../assets/discord.svg";
import TwitterIcon from "../assets/twitter.svg";
import MagicEdenIcon from "../assets/magiceden.svg";
import rockIcon from "../assets/rock.svg";
import paperIcon from "../assets/paper.svg";
import scissorIcon from "../assets/scissor.svg";
import dice1Icon from "../assets/dice1.svg";
import dice2Icon from "../assets/dice2.svg";
import dice3Icon from "../assets/dice3.svg";
import dice4Icon from "../assets/dice4.svg";
import dice5Icon from "../assets/dice5.svg";
import dice6Icon from "../assets/dice6.svg";
import headIcon from "../assets/head.svg";
import tailIcon from "../assets/tail.svg";
import headChillIcon from "../assets/head-chill.svg";
import tailChillIcon from "../assets/tail-chill.svg";

import lightBlueBallIcon from "../assets/light-blue-ball.svg";
import greenBallIcon from "../assets/green-ball.svg";
import pinkBallIcon from "../assets/pink-ball.svg";
import yellowBallIcon from "../assets/yellow-ball.svg";
import grayBallIcon from "../assets/gray-ball.svg";
import blueBallIcon from "../assets/blue-ball.svg";
import goldBallIcon from "../assets/gold-ball.svg";
import redBallIcon from "../assets/red-ball.svg";

import goldCrownIcon from "../assets/crown-gold.svg";
import silverCrownIcon from "../assets/crown-silver.svg";
import bronzeCrownIcon from "../assets/crown-bronze.svg";

import solTicketIcon from "../assets/sol-ticket.svg";

import gDice1Icon from "../assets/gdice1.svg";
import gDice2Icon from "../assets/gdice2.svg";
import gDice3Icon from "../assets/gdice3.svg";
import gDice4Icon from "../assets/gdice4.svg";
import gDice5Icon from "../assets/gdice5.svg";
import gDice6Icon from "../assets/gdice6.svg";

import {
  CoinflipFaceEnum,
  FreeRewardTypeEnum,
  NewYearEventRewardTypeEnum,
  RpsEnum,
  SortTypeEnum,
} from "../enums";

export const socialItmes = [
  {
    id: 1,
    title: "discord",
    to: "https://discord.gg/pZZWHsRKaJ",
    icon: DiscordIcon,
  },
  {
    id: 2,
    title: "twitter",
    to: "https://twitter.com/degen_games_sol",
    icon: TwitterIcon,
  },
  {
    id: 3,
    title: "magiceden",
    to: "https://magiceden.io/marketplace/biteclub",
    icon: MagicEdenIcon,
  },
];

export const rpsItems = [
  {
    id: 1,
    title: "rock",
    icon: rockIcon,
    move: RpsEnum.Rock,
  },
  {
    id: 1,
    title: "paper",
    icon: paperIcon,
    move: RpsEnum.Paper,
  },
  {
    id: 1,
    title: "scissor",
    icon: scissorIcon,
    move: RpsEnum.Scissor,
  },
];

export const diceItems = [
  {
    id: 1,
    title: "dice1",
    icon: dice1Icon,
    move: 1,
  },
  {
    id: 2,
    title: "dice2",
    icon: dice2Icon,
    move: 2,
  },
  {
    id: 3,
    title: "dice3",
    icon: dice3Icon,
    move: 3,
  },
  {
    id: 4,
    title: "dice4",
    icon: dice4Icon,
    move: 4,
  },
  {
    id: 5,
    title: "dice5",
    icon: dice5Icon,
    move: 5,
  },
  {
    id: 6,
    title: "dice6",
    icon: dice6Icon,
    move: 6,
  },
];

export const betAmountList = [0.05, 0.1, 0.25, 0.5, 1, 2];

export const coinflipItems = [
  {
    id: 1,
    title: "head",
    face: CoinflipFaceEnum.Head,
    icon: headIcon,
  },
  {
    id: 1,
    title: "tail",
    face: CoinflipFaceEnum.Tail,
    icon: tailIcon,
  },
];

export const coinflipChillItems = [
  {
    id: 1,
    title: "head",
    face: CoinflipFaceEnum.Head,
    icon: headChillIcon,
  },
  {
    id: 1,
    title: "tail",
    face: CoinflipFaceEnum.Tail,
    icon: tailChillIcon,
  },
];

export const gDiceItems = [
  {
    id: 1,
    title: "dice1",
    icon: gDice1Icon,
    move: 1,
  },
  {
    id: 2,
    title: "dice2",
    icon: gDice2Icon,
    move: 2,
  },
  {
    id: 3,
    title: "dice3",
    icon: gDice3Icon,
    move: 3,
  },
  {
    id: 4,
    title: "dice4",
    icon: gDice4Icon,
    move: 4,
  },
  {
    id: 5,
    title: "dice5",
    icon: gDice5Icon,
    move: 5,
  },
  {
    id: 6,
    title: "dice6",
    icon: gDice6Icon,
    move: 6,
  },
];
export const coinflipBetAmountList = [0.05, 0.1, 0.25, 0.5, 1];
export const dice2BetAmountList = [0.05, 0.1, 0.25, 0.5, 1];

export const colorScheme = [
  "#00BFFF",
  "#FFD700",
  "#40E0D0",
  "#FFDB58",
  "#BA55D3",
  "#46ff78",
  "#00CED1",
  "#7B68EE",
  "#FF4500",
  "#DC143C",
  "#E6E6E6",
  "#FFC1CC",
  "#D8BFD8",
  "#FFA500",
  "#DCDCDC",
  "#CD5C5C",
  "#FFF0F5",
  "#00FA9A",
  "#FFAEB9",
  "#8F8F8F",
  "#FF7F50",
  "#7CFC00",
  "#9370DB",
  "#4682B4",
  "#BAAD8E",
  "#CD853F",
  "#B8860B",
  "#8B008B",
  "#B0C4DE",
  "#ECE5B6",
  "#8FBC8F",
  "#FF6347",
  "#8B0000",
  "#9932CC",
  "#6495ED",
  "#ADFF2F",
  "#C71585",
  "#FFE4E1",
  "#6B8E23",
  "#F4A460",
  "#BFE1F6",
  "#FFA07A",
  "#F5B2C5",
  "#DDDE7C",
  "#8A9DCC",
  "#C3FDB8",
  "#F0E68C",
  "#CD5B45",
  "#DDA0DD",
  "#FFF5EE",
  "#FF8C00",
  "#48D1CC",
  "#DA70D6",
  "#BC8F8F",
  "#4169E1",
  "#4B0082",
  "#556B2F",
  "#008080",
  "#F29F05",
  "#E6E6FA",
  "#FF6B6B",
  "#95D6CB",
  "#FCB4D5",
  "#6A5ACD",
  "#6CC3D5",
  "#FFDAB9",
  "#A3A3D7",
  "#9FE2BF",
  "#FFB347",
  "#77DD77",
  "#D1A0B5",
];

/* Solana ball */
export const buyTicketItems = [
  {
    id: 1,
    ticketCount: 1,
    discountRate: 0,
  },
  {
    id: 2,
    ticketCount: 2,
    discountRate: 0,
  },
  {
    id: 3,
    ticketCount: 5,
    discountRate: 0,
  },
  {
    id: 4,
    ticketCount: 10,
    discountRate: 0,
  },
  {
    id: 5,
    ticketCount: 50,
    discountRate: 5,
  },
  {
    id: 6,
    ticketCount: 100,
    discountRate: 10,
  },
];

export const balls = [
  lightBlueBallIcon,
  greenBallIcon,
  pinkBallIcon,
  yellowBallIcon,
  grayBallIcon,
  blueBallIcon,
  goldBallIcon,
  redBallIcon,
];
/* ----------- */

/* User stats */
export const weekDayData = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};
/* ---------- */

/* Leaderboard */
export const crownItems = [
  {
    id: 1,
    title: "Gold",
    icon: goldCrownIcon,
  },
  {
    id: 2,
    title: "Silver",
    icon: silverCrownIcon,
  },
  {
    id: 3,
    title: "Bronze",
    icon: bronzeCrownIcon,
  },
];

export const sortItems = [
  {
    id: 1,
    title: "Wagered",
    value: SortTypeEnum.TotalVolume,
  },
  {
    id: 2,
    title: "Net Profit",
    value: SortTypeEnum.TotalProfit,
  },
];
/* ----------- */

/* Free Rewards */
export const rewardItems = [
  {
    id: 1,
    type: FreeRewardTypeEnum.Sol,
    amount: 1,
    icon: null,
  },
  {
    id: 2,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.2,
    icon: null,
  },
  {
    id: 3,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.5,
    icon: null,
  },
  {
    id: 4,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 5,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.1,
    icon: null,
  },
  {
    id: 6,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 7,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.05,
    icon: null,
  },
  {
    id: 8,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 9,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.1,
    icon: null,
  },
  {
    id: 10,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 11,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.1,
    icon: null,
  },
  {
    id: 12,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 13,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.2,
    icon: null,
  },
  {
    id: 14,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 15,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.1,
    icon: null,
  },
  {
    id: 16,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 17,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.1,
    icon: null,
  },
  {
    id: 18,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 19,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.05,
    icon: null,
  },
  {
    id: 20,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 21,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.1,
    icon: null,
  },
  {
    id: 22,
    type: FreeRewardTypeEnum.Ticket,
    amount: 0.05,
    icon: solTicketIcon,
  },
  {
    id: 23,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.5,
    icon: null,
  },
  {
    id: 24,
    type: FreeRewardTypeEnum.Sol,
    amount: 0.2,
    icon: null,
  },
];
/* ----------- */

/* New year event */
export const newYearEventDays = [
  {
    task: "Share is caring!",
    details: [
      "Make a screenshot of a cool experience on our platform",
      "Tweet it and tag us @BiteClubSOL",
      "Open a Ticket in our discord and show us your tweet",
      "Claim your reward!",
    ],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "0.1",
  },
  {
    task: "I can’t stop!",
    details: ["Play 100 Games", "Claim your reward!"],
    rewardType: NewYearEventRewardTypeEnum.Bones,
    rewardAmount: "1000",
  },
  {
    task: "Gather your friends!",
    details: [
      "When 10 people flip at the same time in our coinflip game, all 10 people can claim the reward",
      "Claim your reward!",
    ],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "0.25",
  },
  {
    task: "Win  Freak",
    details: ["Win 7 times in a row", "Claim your reward!"],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "1",
  },
  {
    task: "Sharing is caring!",
    details: [
      "Make a screenshot of a cool experience on our platform",
      "Tweet it and tag us @BiteClubSOL",
      "Open a Ticket in our discord and show us your tweet",
      "Claim your reward!",
    ],
    rewardType: NewYearEventRewardTypeEnum.Bones,
    rewardAmount: "2000",
  },
  {
    task: "Wager 10 Sol",
    details: ["Wager 10 Sol", "Claim your reward!"],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "0.1",
  },
  {
    task: "Winner or Loser?",
    details: ["Win OR Lose 8 times in a row", "Claim your reward!"],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "1",
  },
  {
    task: "More more more!",
    details: ["Wager 25 Sol", "Claim your reward!"],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "0.5",
  },
  {
    task: "Sharing is caring!",
    details: [
      "Wager 2 sol",
      "Make a screenshot of a cool experience on our platform",
      "Tweet it and tag us @BiteClubSOL",
      "Open a Ticket in our discord and show us your tweet",
      "Claim your reward!",
    ],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "0.1",
  },
  {
    task: "Sharing is even more caring!",
    details: [
      "Wager 10 sol",
      "Make a screenshot of a cool experience on our platform",
      "Tweet it and tag us @BiteClubSOL",
      "Open a Ticket in our discord and show us your tweet",
      "Claim your reward!",
    ],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "0.25",
  },
  {
    task: "BiteClub Family",
    details: [
      "When 20 people flip at the same time, every Person of the 20 completes task 1",
      "Play 100 Games",
      "Claim your reward!",
    ],
    rewardType: NewYearEventRewardTypeEnum.FeeWager,
    rewardAmount: "4 x 1",
  },
];
/* -------------- */

/* Arena event */
export const arenaEventRewardsList = [0.05, 0.1, 0.25, 0.5, 3];
/* ----------- */

export const questCoolDownPeriod = 14 * 86400;
export const questInitialHealValue = 20;
export const questHealDecreaseamount = 2;
export const questTokenAmountPerHeal = 10;

export const tippingAmountItems = [0.05, 0.1, 0.25, 0.5, 1];
export const storeWagerItems = [
  { wagerAmount: 0.05, bonesAmount: 400 },
  { wagerAmount: 0.1, bonesAmount: 800 },
  { wagerAmount: 0.25, bonesAmount: 2000 },
  { wagerAmount: 0.5, bonesAmount: 4000 },
  { wagerAmount: 1, bonesAmount: 8000 },
];
export const emojiSkinTones = ["1f3fb", "1f3fc", "1f3fd", "1f3fe", "1f3ff"];

export const freeBetToken = "BaP8weJ514ctSpqmKrfFgMqU9MgQFzN6Asufverta5to";

export const audioTracksAdrenaline = [
  {
    id: 1,
    title: "Audio 1",
    src: "/sound/Music/MusicAdrenaline/powerlifting.mp3",
  },
  {
    id: 2,
    title: "Audio 2",
    src: "/sound/Music/MusicAdrenaline/phonkphonk.mp3",
  },
  {
    id: 3,
    title: "Audio 3",
    src: "/sound/Music/MusicAdrenaline/phonk.mp3",
  },
  {
    id: 4,
    title: "Audio 4",
    src: "/sound/Music/MusicAdrenaline/unholyblood.mp3",
  },
  {
    id: 5,
    title: "Audio 5",
    src: "/sound/Music/MusicAdrenaline/adrenalinerush.mp3",
  },
  {
    id: 6,
    title: "Audio 6",
    src: "/sound/Music/MusicAdrenaline/racing.mp3",
  },
  {
    id: 7,
    title: "Audio 7",
    src: "/sound/Music/MusicAdrenaline/pressstart.mp3",
  },
  {
    id: 8,
    title: "Audio 8",
    src: "/sound/Music/MusicAdrenaline/keeponfire.mp3",
  },
  {
    id: 9,
    title: "Audio 9",
    src: "/sound/Music/MusicAdrenaline/dontbreakme.mp3",
  },
  {
    id: 10,
    title: "Audio 10",
    src: "/sound/Music/MusicAdrenaline/ai.mp3",
  },
  {
    id: 11,
    title: "Audio 11",
    src: "/sound/Music/MusicAdrenaline/4am.mp3",
  },
  {
    id: 12,
    title: "Audio 12",
    src: "/sound/Music/MusicAdrenaline/rolling.mp3",
  },
  {
    id: 13,
    title: "Audio 13",
    src: "/sound/Music/MusicAdrenaline/shockabsorber.mp3",
  },
  {
    id: 14,
    title: "Audio 14",
    src: "/sound/Music/MusicAdrenaline/runforyourlife.mp3",
  },
];

export const audioTracksChill = [
  { id: 1, title: "Audio 1", src: "/sound/Music/MusicChill/lofi1-2.mp3" },
  // { id: 2, title: "Audio 2", src: "/sound/Music/MusicChill/lofi1-2.mp3" },
];
