import React from "react";
import { useNavigate } from "react-router-dom";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { CoinflipFaceEnum } from "../../enums";
import CoinflipGame from "../../interfaces/CoinflipGame";
import userIcon from "../../assets/user.svg";
import userGroupLeftIcon from "../../assets/peoples-adrenaline-left.svg";
import userGroupRightIcon from "../../assets/peoples-adrenaline-right.svg";
import solSymbolLeftIcon from "../../assets/sol-symbol-adrenaline-left.svg";
import solSymbolRightIcon from "../../assets/sol-symbol-adrenaline-right.svg";
import useSound from "use-sound";
import Visualizer from "./Visualizer";

interface IProps {
  position?: string;
  currentUsersInfo?: CoinflipGame[] | undefined;
  isCompleted?: boolean;
  winningFace?: CoinflipFaceEnum;
  isMobile?: boolean;
  className?: string;
}

const GameStatsAdrenalineBar = (props: IProps) => {
  const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
  const {
    position,
    currentUsersInfo,
    isCompleted,
    winningFace,
    isMobile,
    className,
  } = props;
  const navigate = useNavigate();
  const userGroupIcon =
    position == "left" ? userGroupLeftIcon : userGroupRightIcon;
  const solSymbolIcon =
    position == "left" ? solSymbolLeftIcon : solSymbolRightIcon;

  const itemFilter =
    position == "left" ? CoinflipFaceEnum.Head : CoinflipFaceEnum.Tail;
  const filteredUsersInfo = (currentUsersInfo || []).filter(
    (x) => x.bettingFace == itemFilter
  );

  return (
    <div
      className={`flex flex-col min-w-[180px] md:min-w-[274px] md:z-20 ${
        className ?? ""
      }`}
    >
      <div className="py-[3px] h-full">
        <div
          className={`
                h-full rounded-[2px] 
                ${
                  position == "left"
                    ? "bg-[linear-gradient(0deg,rgba(255,0,0,0.02)_0%,rgba(255,0,0,0.02)_100%),#11161B] bg-[url('/src/assets/game-stats-adrenaline-left.svg')] bg-center bg-no-repeat bg-cover md:bg-auto"
                    : "bg-[linear-gradient(0deg,rgba(42,226,223,0.02)_0%,rgba(42,226,223,0.02)_100%),#11161B] bg-[url('/src/assets/game-stats-adrenaline-right.svg')] bg-center bg-no-repeat bg-cover md:bg-auto"
                } 
                ${
                  position == "left"
                    ? "shadow-[0px_0px_100px_0px_rgba(255,0,0,0.12)_inset] border-[1px] border-[#F00] rounded-[5px]"
                    : "shadow-[0px_0px_100px_0px_rgba(42,226,223,0.12)_inset] border-[1px] border-[#17A4A1] rounded-[5px]"
                } 
                ${itemFilter == winningFace ? "win-board-adrenaline" : ""}
            `}
        >
          <div
            className={`flex justify-between items-center w-full h-[36px] px-[14px] md:px-[22px] ${position}-coinflip-header-adrenaline ${
              itemFilter == winningFace ? "win-header" : ""
            }`}
          >
            <div className="flex items-center gap-[9px]">
              <img
                className={`${
                  itemFilter == winningFace ? "win-user-group" : ""
                }`}
                src={userGroupIcon}
                alt="user-group"
              />
              <span
                className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${
                  itemFilter == winningFace
                    ? "text-[#00FF00]"
                    : position == "left"
                    ? "text-[#F00]"
                    : "text-[#2AE2DF]"
                } ${itemFilter == winningFace ? "win-numbers" : ""}`}
              >
                {filteredUsersInfo.length}
              </span>
            </div>

            <div className="flex items-center gap-[5px]">
              <span
                className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${
                  itemFilter == winningFace
                    ? "text-[#00FF00]"
                    : position == "left"
                    ? "text-[#F00]"
                    : "text-[#2AE2DF]"
                } ${itemFilter == winningFace ? "win-numbers" : ""}`}
              >
                {filteredUsersInfo.reduce(
                  (total, obj) => total + obj.bettingAmount,
                  0
                ) / LAMPORTS_PER_SOL}
              </span>
              <img
                className={`${
                  itemFilter == winningFace ? "win-sol-symbol" : ""
                }`}
                src={solSymbolIcon}
                alt="sol-symbol"
              />
            </div>
          </div>

          <div className="grow pl-[19px] md:pl-[27px] pr-[44px] md:pr-[52px]">
            {filteredUsersInfo &&
              filteredUsersInfo.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-between items-center w-full mt-[10px]"
                  >
                    <div
                      className="flex items-center gap-[6px] cursor-pointer"
                      onClick={() =>
                        navigate(`/user-stats/${item?.player?.publicKey}`)
                      }
                      onMouseEnter={() => playHover()}
                    >
                      <img src={userIcon} alt="user" />
                      <span
                        className={`text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px] ${
                          isCompleted && item.bettingFace == winningFace
                            ? "text-green-500"
                            : "text-white"
                        }`}
                      >
                        {isMobile
                          ? item.player.username
                            ? item.player.username.length > 4
                              ? String(item.player.username).substring(0, 4) +
                                "..."
                              : item.player.username
                            : String(item.player.publicKey).substring(0, 2) +
                              "..." +
                              String(item.player.publicKey).substring(42)
                          : item.player.username
                          ? item.player.username.length > 12
                            ? String(item.player.username).substring(0, 12) +
                              "..."
                            : item.player.username
                          : String(item.player.publicKey).substring(0, 4) +
                            "..." +
                            String(item.player.publicKey).substring(40)}
                      </span>
                    </div>

                    <span className="text-[10px] md:text-[16px] font-medium leading-[15px] md:leading-[23px]">
                      {item.bettingAmount
                        ? item.bettingAmount / LAMPORTS_PER_SOL
                        : "Free"}
                    </span>
                  </div>
                );
              })}
          </div>
          {/*           <div className="visualizer-container">
            {position === "left" || position === "right" ? (
              <Visualizer
                audioUrl="/sound/Music/MusicAdrenaline/dontbreakme.mp3"
                position={position}
              />
            ) : null}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default GameStatsAdrenalineBar;
