import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import axios from "axios";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as anchor from "@project-serum/anchor";
import { Program, AnchorProvider } from "@project-serum/anchor";
import { Wallet } from '@project-serum/anchor/dist/cjs/provider';
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { coinflipBetAmountList } from "../../data";
import User from "../../interfaces/User";
import Coinflip, { ICoinflipPoolInfo } from "../../interfaces/Coinflip";
import { CoinflipFaceEnum } from "../../enums";
import { CombinedReducer } from "../../store";
import { delay } from "../../utils";
import GameStatsBar from "./GameStasBar";
import HeadLargeIcon from "../../assets/head-large.svg";
import TailLargeIcon from "../../assets/tail-large.svg";
import SpinIcon from "../svgs/SpinIcon";
import GreenCheckIcon from "../svgs/GreenCheckIcon";
import linkIcon from "../../assets/link.svg";
import whiteLinkIcon from "../../assets/link-white.svg";
import closeIcon from "../../assets/close.svg";
import lightGraySolSymbol from "../../assets/sol-symbol-light-gray.svg";
import greenSolSymbol from "../../assets/sol-symbol-green.svg";
import redSolSymbol from "../../assets/sol-symbol-dark-red.svg";
import coinflipIdl from "../../idls/coinflip-idl.json";

import "./index.scss";

enum ClaimingStatusEnum {
    None,
    Claiming,
    Claimed,
    Failed
}

let flag = false;
const GAME_FEE = 4;

const connection = new Connection(process.env.REACT_APP_RPC_URL!, "confirmed");
const network = process.env.REACT_APP_NETWORK;
const programID = new PublicKey(coinflipIdl.metadata.address);

const CoinflipOnchain = () => {
    const wallet = useWallet();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [playCoinflip] = useSound("/sound/pokerchipsound.mp3", { volume: 1 });
    const [playNewBetSound] = useSound('/sound/pop.mp3', { volume: 1 });
    const [playHover] = useSound("/sound/hover.mp3", { volume: 0.1 });
    const [poolInfo, setPoolInfo] = useState<ICoinflipPoolInfo>();
    const [recentRounds, setRecentRounds] = useState<Coinflip[]>([]);
    const [bettingAmount, setBettingAmount] = useState<number>(coinflipBetAmountList[0]);
    const [bettingFace, setBettingFace] = useState<CoinflipFaceEnum>();
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isWinnerSelected, setIsWinnerSelected] = useState<boolean>(false);
    const [isHead, setIsHead] = useState<boolean>(false);
    const [isWinner, setIsWinner] = useState<boolean>(false);
    const [claimingAmount, setClaimingAmount] = useState<number>(coinflipBetAmountList[0]);
    const [treasuryBalance, setTreasuryBalance] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [claimingStatus, setClaimingStatus] = useState<ClaimingStatusEnum>(ClaimingStatusEnum.None);
    const [claimingTxh, setClaimingTxh] = useState<string>('');
    const [showCongratsMsg, setShowCongratsMsg] = useState<boolean>(false);

    const user = useSelector<CombinedReducer, User>((state) => state.user);

    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(coinflipIdl as anchor.Idl, programID, provider);

    const getBalance = async (publicKey: PublicKey) => {
        return await connection.getBalance(publicKey);
    }

    const initialize = async () => {
        try {
            setIsLoading(true);
            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('coinflip-pool')],
                program.programId
            )[0];

            const poolInfo: any = await program.account.pool.fetch(poolAccount);
            setPoolInfo(poolInfo);

            if (wallet?.publicKey) {
                const playerWithdrawingVault = PublicKey.findProgramAddressSync(
                    [
                        Buffer.from('player-withdrawing-vault'),
                        poolAccount.toBuffer(),
                        wallet?.publicKey.toBuffer(),
                    ],
                    program.programId
                )[0];

                const [withdrawingValutBalance, userBalance, treasuryBalance] = await Promise.all([
                    getBalance(playerWithdrawingVault),
                    getBalance(wallet?.publicKey),
                    getBalance(poolInfo?.treasuryVault)
                ]);

                dispatch({ type: "UPDATE_USER_ONCHAIN_BALANCE", payload: userBalance });

                setTreasuryBalance(treasuryBalance);

                if (withdrawingValutBalance > 0) {
                    setIsProcessing(true);
                    setIsWinnerSelected(true);
                    setIsWinner(true);
                    setClaimingAmount(Math.floor((withdrawingValutBalance / LAMPORTS_PER_SOL / 2) * 1000) / 1000)
                }
            }
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    const handlePlayAgain = () => {
        setBettingFace(undefined);
        setIsCompleted(false);
        setIsWinnerSelected(false);
        setIsWinner(false);
        setIsProcessing(false);
        setClaimingStatus(ClaimingStatusEnum.None);
        setShowCongratsMsg(false);
        setClaimingTxh('');
    }

    const getValidStatus = (amount: number): boolean => {
        if (
            amount == 0.05 && treasuryBalance < 6_250_000_000
            || amount == 0.1 && treasuryBalance < 12_500_000_000
            || amount == 0.25 && treasuryBalance < 31_250_000_000
            || amount == 0.5 && treasuryBalance < 62_500_000_000
            || amount == 1 && treasuryBalance < 125_000_000_000
        ) return false;
        else return true;
    }

    const handleBetGame = async (betFace: CoinflipFaceEnum) => {
        try {
            if (!user?.publicKey) {
                return toast.warn("Please connect wallet!");
            }

            if (!poolInfo) {
                return toast.warn("Pool is not initialized yet!");
            }

            setBettingFace(betFace);
            setIsProcessing(true);

            const player = provider.wallet.publicKey;

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('coinflip-pool')],
                program.programId
            )[0];

            const playerFundingVault = PublicKey.findProgramAddressSync(
                [
                    Buffer.from('player-funding-vault'),
                    poolAccount.toBuffer(),
                    player.toBuffer(),
                ],
                program.programId
            )[0];

            const [playerBetAccount] = PublicKey.findProgramAddressSync(
                [
                    Buffer.from('player-bet-account'),
                    poolAccount.toBuffer(),
                    player.toBuffer(),
                ],
                program.programId
            );

            const amount = new anchor.BN(bettingAmount * LAMPORTS_PER_SOL);

            const txh = await program.methods
                .betSol(amount, betFace)
                .accounts(
                    {
                        player: player,
                        pool: poolAccount,
                        treasuryVault: poolInfo?.treasuryVault,
                        playerFundingVault: playerFundingVault,
                        playerBetAccount: playerBetAccount,
                        feeCollector: poolInfo?.feeCollector,
                        systemProgram: anchor.web3.SystemProgram.programId,
                    }
                )
                .rpc();

            console.log('txh: ', txh);

            try {
                await delay(500);
                const [userBalance, treasuryBalance] = await Promise.all([
                    getBalance(player),
                    getBalance(poolInfo?.treasuryVault)
                ]);

                dispatch({ type: "UPDATE_USER_ONCHAIN_BALANCE", payload: userBalance });
                setTreasuryBalance(treasuryBalance);
            } catch { }
        } catch (e) {
            console.log('e', e);
            setBettingFace(undefined);
            setIsProcessing(false);
            toast.error("Failed to flip");
        }
    }

    const handleClaiming = async () => {
        try {
            if (!user?.publicKey) {
                return toast.warn("Please connect wallet!");
            }

            if (!poolInfo) {
                return toast.warn("Pool is not initialized yet!");
            }

            setClaimingStatus(ClaimingStatusEnum.Claiming);
            setShowCongratsMsg(true);

            const player = provider.wallet.publicKey;

            const poolAccount = PublicKey.findProgramAddressSync(
                [Buffer.from('coinflip-pool')],
                program.programId
            )[0];

            const [playerWithdrawingVault, playerWithdrawingVaultBump] = PublicKey.findProgramAddressSync(
                [
                    Buffer.from('player-withdrawing-vault'),
                    poolAccount.toBuffer(),
                    player.toBuffer(),
                ],
                program.programId
            );

            const txh = await program.methods
                .claimSol(playerWithdrawingVaultBump)
                .accounts(
                    {
                        player: player,
                        pool: poolAccount,
                        playerWithdrawingVault: playerWithdrawingVault,
                        systemProgram: anchor.web3.SystemProgram.programId,
                    }
                )
                .rpc();

            console.log('txh: ', txh);
            setClaimingTxh(txh);
            setClaimingStatus(ClaimingStatusEnum.Claimed);

            try {
                await delay(500);
                const [userBalance, treasuryBalance] = await Promise.all([
                    getBalance(player),
                    getBalance(poolInfo?.treasuryVault)
                ]);

                dispatch({ type: "UPDATE_USER_ONCHAIN_BALANCE", payload: userBalance });
                setTreasuryBalance(treasuryBalance);
            } catch { }
        } catch (e) {
            console.log('e', e);
            setClaimingStatus(ClaimingStatusEnum.Failed);
            setShowCongratsMsg(false);
            toast.error("Failed to claim reward");
        }
    }

    useEffect(() => {
        program.addEventListener("DrawWinnerEvent", (event, _slot) => {
            const { player, isWinner, winningFace } = event;

            if (player?.toString()?.toLowerCase() == user?.publicKey?.toLowerCase()) {
                setIsHead(winningFace == 0 ? true : false);
                setIsCompleted(true);
                setIsWinner(isWinner);

                // Play the coin flip sound right when winner is determined
                playCoinflip();

                setTimeout(() => {
                    setIsWinnerSelected(true);
                }, 3500)
            }
        });
    }, [user, playCoinflip]);

    useEffect(() => {
        (async () => {
            await initialize();
        })();
    }, [wallet?.publicKey, user?.publicKey]);

    return (
        <div className="flex flex-col md:flex-row justify-center min-h-full h-fit px-[5px]">
            <GameStatsBar
                position="left"
                className="hidden md:block blur-[5px] z-[1050]"
            />

            <div className="flex flex-col md:grow bg-[#090C0E] relative">
                {/* Link button */}
                <div className="absolute top-[6px] md:top-[8px] right-[16px] md:right-[21px]">
                    <div className="flex justify-center items-center gap-[19px] md:gap-[12px] pt-10">
                        <div className="flex justify-center items-center w-[40px] h-[40px] border border-solid border-[#808080] rounded-full bg-[#222c36]">
                            <img src={whiteLinkIcon} className="w-[25px] h-[25px]" alt="link-icon" />
                        </div>

                        <button
                            className="flex justify-center items-center w-[20px] h-[20px] hover:scale-110 duration-150"
                            onClick={() => navigate('/coinflip')}
                            onMouseEnter={() => playHover()}
                        >
                            <img src={closeIcon} className="w-full" alt="close-icon" />
                        </button>
                    </div>
                </div>
                <div className="flex justify-center md:items-center grow mt-[15px] md:mt-0 pt-40">
                    <div>
                        {/* Coinflip */}
                        <div className="flex justify-center w-[140px] md:w-[250px] aspect-square mt-[100px] md:mt-[0px] mx-auto">
                            {
                                recentRounds.length > 0 ? (
                                    recentRounds[recentRounds.length - 1].winningFace == CoinflipFaceEnum.Head ? (
                                        <img src={HeadLargeIcon} alt="coin" className={`w-full rotate-180 ${!isCompleted ? 'block' : 'hidden'}`} />
                                    ) : (
                                        <img src={TailLargeIcon} alt="coin" className={`w-full ${!isCompleted ? 'block' : 'hidden'}`} />
                                    )
                                ) : (
                                    <img src={HeadLargeIcon} alt="coin" className={`w-full rotate-180 ${!isCompleted ? 'block' : 'hidden'}`} />
                                )
                            }

                            <div
                                id="coin"
                                className={`flip-status ${isHead ? "heads" : "tails"} ${isCompleted ? 'block' : 'hidden'}`}
                            >
                                <div className="side-a-chill"></div>
                                <div className="side-b-chill"></div>
                            </div>
                        </div>

                        {
                            !isProcessing ? (
                                <>
                                    {/* Bet amount list */}
                                    <div className="relative w-full flex flex-wrap justify-center items-center gap-[10px] md:gap-[20px] mt-[57px] md:mt-[100px]">
                                        {
                                            coinflipBetAmountList.map((item, index) => {
                                                return (
                                                    <button
                                                        disabled={isLoading || !getValidStatus(item)}
                                                        key={index}
                                                        className={`onchain-hover-bet-buttons
                                                            w-[51px] h-[31px] md:w-[100px] md:h-[60px] text-[12px] md:text-[22px] font-medium leading-[17px] md:leading-[32px] rounded-[2px]
                                                            ${getValidStatus(item) ? 'hover:text-white' : ''}
                                                            ${bettingAmount == item ? 'active border-2 border-solid border-transparent' : 'border-2 border-solid border-[#808080]/50 text-[#808080]'} 
                                                            ${isLoading ? 'opacity-30' : 'opacity-100'}
                                                        `}
                                                        onClick={() => {
                                                            setBettingAmount(item);
                                                            setClaimingAmount(item);
                                                        }}
                                                        onMouseEnter={() => playHover()}
                                                    >
                                                        {
                                                            getValidStatus(item) && (
                                                                <>
                                                                    <span className="special-hover"></span>
                                                                    <span className="special-hover"></span><span className="special-hover"></span><span className="special-hover"></span>
                                                                </>
                                                            )
                                                        }
                                                        {item}
                                                    </button>
                                                );
                                            })
                                        }
                                    </div>

                                    {/* Control buttons */}
                                    <div className="w-full flex flex-wrap justify-center items-center gap-[10px] md:gap-[20px] mt-[25px] md:mt-[50px]">
                                        <button
                                            disabled={isLoading}
                                            className={`
                                                flex justify-center items-center gap-2 head-btn heads-tails-button w-[170px] h-[40px] md:w-[340px] md:h-[70px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] 
                                                ${isLoading ? 'opacity-30' : 'opacity-100'}
                                            `}
                                            onClick={() => handleBetGame(CoinflipFaceEnum.Head)}
                                            onMouseEnter={() => playHover()}
                                        >
                                            HEADS
                                        </button>
                                        <button
                                            disabled={isLoading}
                                            className={`
                                                flex justify-center items-center gap-2 tail-btn heads-tails-button w-[170px] h-[40px] md:w-[340px] md:h-[70px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] 
                                                ${isLoading ? 'opacity-30' : 'opacity-100'}
                                            `}
                                            onClick={() => handleBetGame(CoinflipFaceEnum.Tail)}
                                            onMouseEnter={() => playHover()}
                                        >
                                            TAILS
                                        </button>
                                    </div>
                                </>
                            ) : (
                                isWinnerSelected ? (
                                    isWinner ? (
                                        <div className="w-full flex flex-col justify-center items-center mt-[27px] md:mt-[100px]">
                                            <span className="text-[14px] md:text-[20px] font-bold leading-[100%] mb-[10px] md:mb-0">YOU WON</span>
                                            <div className="flex justify-center items-center gap-[5px]">
                                                <span className="text-[20px] md:text-[30px] text-[#46FF78] font-bold leading-[100%]">{claimingAmount * 2}</span>
                                                <img src={greenSolSymbol} className="w-[17px] md:w-[22px]" alt="sol-symbol" />
                                            </div>
                                            {
                                                claimingStatus != ClaimingStatusEnum.None ? (
                                                    <>
                                                        <div className="flex flex-col justify-center items-center mt-[15px]">
                                                            {
                                                                claimingStatus == ClaimingStatusEnum.Claiming && (
                                                                    <>
                                                                        <SpinIcon className="coinflip-spin w-[40px] md:w-[50px]" />
                                                                        <span className="text-[16px] text-[#46FF78] font-bold mt-[15px]">Doubling your Sol on chain...</span>
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                claimingStatus == ClaimingStatusEnum.Claimed && (
                                                                    <>
                                                                        <GreenCheckIcon className="w-[40px]" />
                                                                        <div className="flex justify-center items-baseline w-full gap-[9px] mt-[15px]">
                                                                            <span className="text-[16px] text-[#46FF78] font-bold">Your Sol has been successfully doubled</span>
                                                                            <a
                                                                                href={`https://solscan.io/tx/${claimingTxh}${network == 'devnet' ? '?cluster=devnet' : ''}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <img src={linkIcon} className="w-[17px]" alt="link-icon" />
                                                                            </a>
                                                                        </div>
                                                                        <button
                                                                            className='flex justify-center items-center bg-[rgba(0,165,254,0.20)] shadow-[0px_0px_20px_-1px_#00A5FE] border-2 border-solid border-[#00A5FE] rounded-full w-[170px] h-[40px] md:w-[276px] md:h-[74px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] mt-[15px]'
                                                                            onClick={handlePlayAgain}
                                                                            onMouseEnter={() => playHover()}
                                                                        >
                                                                            Play Again
                                                                        </button>
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                claimingStatus == ClaimingStatusEnum.Failed && (
                                                                    <>
                                                                        <span className="text-[16px] text-[#FF0000] font-bold mt-[15px]">Failed to double your Sol. Please Refresh browser.</span>
                                                                        <button
                                                                            className='flex justify-center items-center bg-[rgba(0,165,254,0.20)] shadow-[0px_0px_20px_-1px_#00A5FE] border-2 border-solid border-[#00A5FE] rounded-full w-[170px] h-[40px] md:w-[276px] md:h-[74px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] mt-[30px]'
                                                                            onClick={() => window.location.reload()}
                                                                            onMouseEnter={() => playHover()}
                                                                        >
                                                                            Try Again
                                                                        </button>

                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                ) : (
                                                    <button
                                                        className='flex justify-center items-center bg-[rgba(70,255,120,0.20)] shadow-[0px_0px_20px_-1px_#46FF78] border-2 border-solid border-[#46FF78] rounded-full w-[170px] h-[40px] md:w-[276px] md:h-[74px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] mt-[15px]'
                                                        onClick={handleClaiming}
                                                        onMouseEnter={() => playHover()}
                                                    >
                                                        CLAIM REWARD
                                                    </button>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div className="w-full flex flex-col justify-center items-center mt-[27px] md:mt-[100px]">
                                            <span className="text-[14px] md:text-[20px] font-bold leading-[100%] mb-[10px] md:mb-0">YOU LOST</span>
                                            <div className="flex justify-center items-center gap-[5px]">
                                                <span className="text-[20px] md:text-[30px] text-[#FF0000] font-bold leading-[100%]">{claimingAmount}</span>
                                                <img src={redSolSymbol} className="w-[17px] md:w-[22px]" alt="sol-symbol" />
                                            </div>
                                            <button
                                                className='flex justify-center items-center bg-[rgba(0,165,254,0.20)] shadow-[0px_0px_20px_-1px_#00A5FE] border-2 border-solid border-[#00A5FE] rounded-full w-[170px] h-[40px] md:w-[276px] md:h-[74px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px] mt-[30px]'
                                                onClick={handlePlayAgain}
                                            >
                                                Play Again
                                            </button>
                                        </div>
                                    )
                                ) : (
                                    <div className="w-full flex flex-col justify-center items-center mt-[57px] md:mt-[100px]">
                                        <div className='flex justify-center items-center processing-btn w-[170px] h-[40px] md:w-[276px] md:h-[74px] text-[12px] md:text-[24px] font-extrabold leading-[18px] md:leading-[36px]'>
                                            Processing...
                                        </div>

                                        <div className="flex justify-center items-center gap-[6px] mt-[9px] md:mt-[18px]">
                                            <span className="text-[14px] md:text-[24px] font-medium leading-[100%]">{`${bettingFace == CoinflipFaceEnum.Head ? 'Heads' : 'Tails'} for ${bettingAmount}`}</span>
                                            <img src={lightGraySolSymbol} className="w-[12px] md:w-[18px]" alt="sol-symbol" />
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </div >

            {/* Mobile game stats */}
            < div className="grow w-full flex justify-center gap-[10px] bg-[#090C0E] pt-[24px] pb-[60px] md:hidden" >
            </div >

            <GameStatsBar
                position="right"
                className="hidden md:block blur-[5px]"
            />

            {/* {
                showCongratsMsg && (
                    <div className="fixed top-[4px] left-[4px] sm:left-[279px] xl:left-[434px]">
                        <div className="w-[200px] md:w-[268px] h-[50px] md:h-[60px] processing-btn !rounded-[2px] before:!rounded-[2px] relative">
                            <button
                                className="absolute top-[7px] right-[8px] flex justify-center items-center w-[10px]"
                                onClick={() => setShowCongratsMsg(false)}
                                onMouseEnter={() => playHover()}
                            >
                                <img src={closeIcon} alt='close-icon' className="w-full" />
                            </button>

                            <div className="flex flex-col justify-center items-center text-[12px] md:text-[16px] font-bold w-full h-full px-[10px]">
                                <span>Congratulations! Double or Nothing?</span>
                            </div>
                        </div>
                    </div>
                )
            } */}

        </div >
    );
};

export default CoinflipOnchain;